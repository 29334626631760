import { BackendAdminApiClient, createDefaultHttpParams } from '@monsido/modules/endpoints/api/backend-admin-api-client';
import { Injectable } from '@angular/core';
import { PlainHttpParams } from '@monsido/http/params';
import { HttpHeaders } from '@angular/common/http';
import { Domain } from '@monsido/modules/models/api/domain';

@Injectable({
    providedIn: 'root',
})
export class BackendAdminDomainsRepo {

    constructor (private backendAdminApiClient: BackendAdminApiClient) { }

    public getDomain (customerId: number, id: number, data?: PlainHttpParams, headers?: HttpHeaders): Promise<Domain> {
        const params = createDefaultHttpParams(data, headers);
        return this.backendAdminApiClient.getPromise(`customers/${customerId}/domains/${id}`, params).then(this.toModel);
    }

    public getDomainCrawls (options: {customerId: number, domainId: number, data: PlainHttpParams}): Promise<unknown> {
        const { domainId, customerId, data } = options;
        const params = createDefaultHttpParams(data);
        return this.backendAdminApiClient.getPromise(`customers/${customerId}/domains/` + domainId + '/crawls', params);
    }

    public update (domain: Domain): Promise<Domain> {
        return this.backendAdminApiClient.patchPromise(`customers/${domain.customer_id}/domains/${domain.id}`, domain).then(this.toModel);
    }

    public getAll (data: PlainHttpParams, headers?: HttpHeaders): Promise<Domain[]> {
        const params = createDefaultHttpParams(data, headers);
        return this.backendAdminApiClient.getPromise('domains', params).then(this.toModels.bind(this));
    }

    public destroy (customerId: number, domainId: number, data: PlainHttpParams, headers: HttpHeaders): Promise<void> {
        const params = createDefaultHttpParams(data, headers);
        return this.backendAdminApiClient.deletePromise(`customers/${customerId}/domains/${domainId}`, params);
    }

    // private
    private toModels (models: Domain[]): Domain[] {
        for (let i = 0; i < models.length; i++) {
            models[i] = this.toModel(models[i]);
        }
        return models;
    }

    private toModel (model: Domain): Domain {
        return new Domain(model);
    }
}
