<div ngForm="domainScanFieldsForm" #domainScanFieldsForm="ngForm">
<div class="form-group">
    <label class="col-sm-8 text-bold capitalize-first-letter text-right pt-1" for="domain_active" translate>Crawl Automatically</label>
    <div class="select-grey col-sm-40">
        <mon-switch
            [(ngModel)]="monDomain.active"
            [monLabel]="'domain_active'"
            [name]="'domain_active'"></mon-switch>
    </div>
</div>

<div *ngIf="monDomain.active">
    <mon-form-field-select
        [monName]="'scan_interval'"
        [(model)]="scanIntervalValue"
        (modelChange)="setScanInterval()"
        monLabel="{{ 'Scan frequency' | translate }}"
        monPlaceholder="{{ 'Scan Interval' | translate }}"
        [monOptions]="scanIntervalOptions"
        [monReturnValue]="'value'">
    </mon-form-field-select>

    <mon-form-field-select
        [(model)]="monDomain.scan.day"
        monLabel="{{ 'Day' | translate }}"
        monPlaceholder="{{ 'Day' | translate }}"
        [monOptions]="dayOptions"
        [monName]="'scan.day'"
        [monReturnValue]="'value'">
    </mon-form-field-select>

    <div class="form-group mt-2">
        <label class="col-form-label col-sm-8" translate>
            Time
        </label>
        <div class="col-sm-40">
            <ngb-timepicker
                class="hours-only"
                [(ngModel)]="scanTime"
                [spinners]="false"
                [meridian]="true"
                name="domain.scan.time"
                id="domain-scan"></ngb-timepicker>
        </div>
    </div>
</div>

<mon-form-field-select
    [monName]="'connections_per_minute'"
    [(model)]="monDomain.scan.connections_per_minute"
    monLabel="{{ 'Connections per minute' | translate }}"
    monPlaceholder="{{ 'Connections per minute' | translate }}"
    [monOptions]="connectionOptions"
    [monReturnValue]="'value'">
</mon-form-field-select>

<mon-panel-header [eClass]="'outer-t-large'"
    [hideHr]="true"
    pHeader="{{'Max' | translate}}">
</mon-panel-header>

<mon-form-field-input
    [name]="'max_scanned_links'"
    [(ngModel)]="monDomain.scan.max_scanned_links"
    monLabel="{{ 'Max scanned links' | translate }}"
    monPlaceholder="{{ 'Max scanned links' | translate }}"
    [type]="'text'">
</mon-form-field-input>

<mon-form-field-input
    [name]="'max_scanned_pages'"
    [(ngModel)]="monDomain.scan.max_scanned_pages"
    monLabel="{{ 'Max scanned pages' | translate }}"
    monPlaceholder="{{ 'Max scanned pages' | translate }}"
    [type]="'text'">
</mon-form-field-input>

<ng-container *ngIf="monIsBackendAdmin">
    <mon-form-field-input
        [name]="'max_scan_depth'"
        #max_scan_depth="ngModel"
        [(ngModel)]="monDomain.scan.max_scan_depth"
        monLabel="{{ 'Max scan depth' | translate }}"
        monPlaceholder="{{ 'Max scan depth' | translate }}"
        [type]="'number'"
        [min]="0"
        [monMin]="0">
    </mon-form-field-input>
    <mon-form-field-errors
        [showError]="max_scan_depth.control.touched && max_scan_depth.control.invalid"
        [errors]="max_scan_depth.control.errors">
    </mon-form-field-errors>
</ng-container>

<mon-panel-header [eClass]="'outer-t-large'"
    [hideHr]="true"
    pHeader="{{'Crawl Options' | translate}}">
</mon-panel-header>

<div class="row mt-2">
    <div class="col-sm-16 col-sm-offset-2">
        <div class="pl-3 form-group">
            <label for="scan_scan_subdomains"
                class="display-none" translate>Scan
                subdomains</label>
            <mon-form-field-checkbox
                monId="scan_scan_subdomains"
                [monName]="'scan_scan_subdomains'"
                [(model)]="monDomain.scan.scan_subdomains"
                monHelpText="{{ 'Scan subdomains' | translate }}"
                id="scan_scan_subdomains"></mon-form-field-checkbox>
        </div>
        <div class="pl-3 form-group">
            <label
                for="scan_spelling_ignore_capitalized_words"
                class="display-none" translate>
                Spelling ignore capitalized words
            </label>
            <mon-form-field-checkbox
                monId="scan_spelling_ignore_capitalized_words"
                [monName]="'scan_spelling_ignore_capitalized_words'"
                [(model)]="monDomain.scan.spelling_ignore_capitalized_words"
                monHelpText="{{ 'Spelling ignore capitalized words' | translate }}"
                id="scan_spelling_ignore_capitalized_words"></mon-form-field-checkbox>
        </div>
        <div class="pl-3 form-group">
            <label for="scan_case_sensitive"
                class="display-none" translate>Case
                sensitive URLs</label>
            <mon-form-field-checkbox
                monId="scan_case_sensitive"
                [monName]="'scan_case_sensitive'"
                [(model)]="monDomain.scan.case_sensitive"
                monHelpText="{{ 'Case sensitive URLs' | translate }}"
                id="scan_case_sensitive"></mon-form-field-checkbox>
        </div>
        <div class="pl-3 form-group">
            <label for="scan_use_lang_attribute"
                class="display-none" translate>Language
                and locale detection</label>
            <mon-form-field-checkbox
                monId="scan_use_lang_attribute"
                [monName]="'scan_use_lang_attribute'"
                [(model)]="monDomain.scan.use_lang_attribute"
                monHelpText="{{ 'Language and locale detection' | translate }}"
                id="scan_use_lang_attribute"></mon-form-field-checkbox>
        </div>
    </div>
    <div class="col-sm-24">
        <div class="pl-3 form-group">
            <label for="scan_enable_javascript"
                class="display-none" translate>
                Render pages and execute JS while
                crawling
            </label>
            <mon-form-field-checkbox
                monId="scan_enable_javascript"
                [monName]="'scan_enable_javascript'"
                [(model)]="monDomain.scan.enable_javascript"
                monHelpText="{{ 'Render pages and execute JS while crawling' | translate }}"
                id="scan_enable_javascript"></mon-form-field-checkbox>
        </div>
        <div class="pl-3 form-group">
            <label for="scan_auth_required_as_error"
                class="display-none" translate>
                Mark 403 as dead link
            </label>
            <mon-form-field-checkbox
                monId="scan_auth_required_as_error"
                [monName]="'scan_auth_required_as_error'"
                [(model)]="monDomain.scan.auth_required_as_error"
                monHelpText="{{ 'Mark 403 as dead link' | translate }}"
                id="scan_auth_required_as_error"></mon-form-field-checkbox>
        </div>
        <div class="pl-3 form-group">
            <label for="scan_ignore_canonical"
                class="display-none" translate>
                Ignore canonical URLs
            </label>
            <mon-form-field-checkbox
                monId="scan_ignore_canonical"
                [monName]="'scan_ignore_canonical'"
                [(model)]="monDomain.scan.ignore_canonical"
                monHelpText="{{ 'Ignore canonical URLs' | translate }}"
                id="scan_ignore_canonical"></mon-form-field-checkbox>
        </div>
    </div>
</div>

<mon-panel-header [eClass]="'outer-t-large'"
    pHeader="{{'Domain Options' | translate}}"
    [hideHr]="true">
</mon-panel-header>

<div class="clearfix">
    <div class="col-sm-offset-2 col-sm-40">
        <h3 class="font-weight-300" translate>Path constraints</h3>
    </div>
    <mon-csv-import-button
        monProp='path_constraints'
        [monHeader]="'Import constraints' | translate"
        [monSelectLabel]="'Path Constraints' | translate"
        (closeChange)="onClose($event)"
        class="pull-right position-relative"></mon-csv-import-button>
</div>

<div class="clearfix mb-5">
    <mon-spinner [loading]="isContstraintsLoading">
        <mon-form-field-dynamic-ac
                class="field-with-label"
                (monLabelDeleteButtonClick)="onDeleteConstraintsDefaultLabelClick($event)"
                [attrName]="'constraint'"
                name="path_constraints"
                [ngModel]="monDomain.path_constraints"
                (inputValueChanged)="onInputValueChanged($event, 'pathConstraints')"
                [monDynamicComponentRightItemLabels]="constraintsRightItemLabels"
                [monRightItemLabelsTooltip]="labelTooltipText"
                [allowSearch]="true"
                [pageSize]="5"
                [allowPagination]="true"
                label="{{'Constraint pattern' | translate}}"
                placeholder="{{'Constraint pattern' | translate}}"
                type="text"
                confirmMsg="{{'Remove this pattern?' | translate}}">
        </mon-form-field-dynamic-ac>
    </mon-spinner>
</div>

<div class="clearfix">
    <div class="col-sm-offset-2 col-sm-40">
        <h3 class="font-weight-300" translate>Link excludes</h3>
    </div>
    <mon-csv-import-button
        monProp='link_excludes'
        [monHeader]="'Import link excludes' | translate"
        [monSelectLabel]="'Link Excludes' | translate"
        (closeChange)="onClose($event)"
        class="pull-right position-relative"></mon-csv-import-button>
</div>

<div class="clearfix mb-5">
    <mon-spinner [loading]="isContstraintsLoading">
        <mon-form-field-dynamic-ac
                class="field-with-label"
                (monLabelDeleteButtonClick)="onDeleteLinksDefaultLabelClick($event)"
                [attrName]="'regex'"
                name="link_excludes"
                [ngModel]="monDomain.link_excludes"
                (inputValueChanged)="onInputValueChanged($event, 'linkExcludes')"
                [monDynamicComponentRightItemLabels]="linkExcludesRightItemLabels"
                [monRightItemLabelsTooltip]="labelTooltipText"
                [allowSearch]="true"
                [pageSize]="5"
                [allowPagination]="true"
                label="{{'Exclude pattern' | translate}}"
                placeholder="{{'Exclude pattern' | translate}}"
                type="text"
                confirmMsg="{{'Remove this pattern?' | translate}}">
        </mon-form-field-dynamic-ac>
    </mon-spinner>
</div>

<div class="col-sm-offset-2 col-sm-40">
    <h3 class="font-weight-300" translate>Internal URLs
    </h3>
</div>

<mon-form-domain-scan-internal-urls
    [monInternalUrls]="monDomain.additional_internal_urls">
</mon-form-domain-scan-internal-urls>

<mon-panel-header [eClass]="'mt-6'"
    pHeader="{{'Login type' | translate}}"
    [hideHr]="true">
</mon-panel-header>

<div class="mb-3 form-group">
    <label for="scan_login"
        class="col-sm-8 pr-0 col-form-label" translate>
        Select login type*
    </label>
    <div class="col-sm-40">
        <mon-form-field-select
            id="scan_login"
            [monAllowClear]="false"
            [monNameAttr]="'name'"
            [monReturnValue]="'value'"
            [monName]="'scan_login'"
            monPlaceholder="{{'Choose login type' | translate}}"
            [monOptions]="loginTypes"
            [(model)]="monDomain.scan.login.type"
            [monRequired]="true">
        </mon-form-field-select>
    </div>
</div>
<mon-form-domain-scan-login-types-form
    *ngIf="monDomain.scan.login.type === 'form'"
    [monForm]="monForm"
    [monLogin]="$loginTypeForm(monDomain.scan.login)">
</mon-form-domain-scan-login-types-form>
<mon-form-domain-scan-login-types-office365
    *ngIf="monDomain.scan.login.type === 'office365'"
    [monForm]="monForm"
    [monLogin]="$office365Type(monDomain.scan.login)">
</mon-form-domain-scan-login-types-office365>
<mon-form-domain-scan-login-types-basic-auth
    *ngIf="monDomain.scan.login.type === 'basic_auth'"
    [monForm]="monForm"
    [monUrl]="monDomain.url"
    [monLogin]="$basicAuthType(monDomain.scan.login)">
</mon-form-domain-scan-login-types-basic-auth>
<mon-form-domain-scan-login-types-custom-multistep
    *ngIf="monDomain.scan.login.type === 'custom_multistep'"
    [monForm]="monForm"
    [monLogin]="$multistepType(monDomain.scan.login)">
</mon-form-domain-scan-login-types-custom-multistep>
</div>
